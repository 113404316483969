import NProgress from "nprogress"

document.addEventListener('DOMContentLoaded', () => {
    NProgress.start()
})

window.addEventListener('load', () => {
    let body = document.body
    let loader = document.getElementById('loader')
    body.classList.add('loaded')
    loader.classList.add('loader-fade')
    NProgress.done();
}, true)
