import "core-js/stable"
import "regenerator-runtime/runtime"

import "./app.scss"

import Prism from 'prismjs'
import "./js/bootstrap"
import "./js/bootstrap-icons"
import "./js/loader"

/*
  To include modules, import and include them anywhere in your JS. For example,
  uncommenting the following two lines will force the jquery chunk to be
  generated. Normally, only code that is actually used will be output as modules.
 */
//import $ from "jquery"
//console.log( $ )


// Import PrismJS extensions
import "prismjs/themes/prism-okaidia.css"
import "prismjs/components/prism-scss"

import "prismjs/plugins/show-language/prism-show-language"

Prism.highlightAll()

// Only used in dev
if (module.hot) {
    module.hot.accept()
}
